<template>
  <div class="physicianVisits">
    <div class="yybz">
      <p>主诉：你是否有如下的用眼情况和需求?</p>
      <el-input v-model="postData.InquiryRemark1" class="yybz-sr" placeholder="请输入内容"></el-input>
    </div>
    <div class="nr-box">
      <el-checkbox-group v-model="checkList">
        <div class="nr-content">
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[0]"
                  :preview-src-list="srcList">
              </el-image>
              <p>眼睛干涩</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[0]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="0"></el-checkbox>
              <el-input v-model="cxList[0]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[1]"
                  :preview-src-list="srcList">
              </el-image>
              <p>疲劳/胀痛</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[1]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="1"></el-checkbox>
              <el-input v-model="cxList[1]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[2]"
                  :preview-src-list="srcList">
              </el-image>
              <p>眼睛痒</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[2]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="2"></el-checkbox>
              <el-input v-model="cxList[2]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[3]"
                  :preview-src-list="srcList">
              </el-image>
              <p>眼睛溢泪</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[3]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="3"></el-checkbox>
              <el-input v-model="cxList[3]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[4]"
                  :preview-src-list="srcList">
              </el-image>
              <p>分泌物多</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[4]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="4"></el-checkbox>
              <el-input v-model="cxList[4]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[5]"
                  :preview-src-list="srcList">
              </el-image>
              <p>验光配镜</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[5]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="5"></el-checkbox>
              <el-input v-model="cxList[5]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[6]"
                  :preview-src-list="srcList">
              </el-image>
              <p>远看模糊</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[6]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="6"></el-checkbox>
              <el-input v-model="cxList[6]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[7]"
                  :preview-src-list="srcList">
              </el-image>
              <p>近看模糊</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[7]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="7"></el-checkbox>
              <el-input v-model="cxList[7]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[8]"
                  :preview-src-list="srcList">
              </el-image>
              <p>视物扭曲缺损</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[8]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="8"></el-checkbox>
              <el-input v-model="cxList[8]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[9]"
                  :preview-src-list="srcList">
              </el-image>
              <p>视野缺损</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[9]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="9"></el-checkbox>
              <el-input v-model="cxList[9]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[10]"
                  :preview-src-list="srcList">
              </el-image>
              <p>黑影飞蚊症</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[10]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="10"></el-checkbox>
              <el-input v-model="cxList[10]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>

          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="srcList[11]"
                  :preview-src-list="srcList">
              </el-image>
              <p>复查管理</p>
            </div>
            <div class="xzx">
              <el-select v-model="ybList[11]" class="zxz-sr" placeholder="眼别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <el-checkbox class="fxk" label="11"></el-checkbox>
              <el-input v-model="cxList[11]" class="zxz-sr" placeholder="持续时间"></el-input>
            </div>
          </div>
        </div>
      </el-checkbox-group>
    </div>

    <div class="qt">
      <el-radio-group v-model="sex">
        <el-radio disabled :label="'1'">男</el-radio>
        <el-radio disabled :label="'0'">女</el-radio>
      </el-radio-group>
      <div class="xz">
        <div class="xz-box" v-if="isAdult">
          <p>出生日期</p>
          <el-input class="srpp" v-model="srpp" disabled></el-input>
        </div>
        <div class="xz-box" v-if="!isAdult">
          <p>年龄</p>
          <el-input class="srpp" v-model="srpp" disabled></el-input>
        </div>
        <div class="xz-box" v-if="isAdult">
          <p>特殊需求</p>
          <el-row class="demo-autocomplete">
            <el-col :span="12" class="srpp">
              <el-autocomplete
                  class="inline-input srpp"
                  v-model="postData.InquiryOccupation"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  @select="handleSelect"
              ></el-autocomplete>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-button @click="dialogVisible=true" class="sgn">视功能问卷</el-button>
    </div>

    <div class="teenager" v-if="!isAdult">
      <div class="teenager-box">
        <p>身高</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_height" placeholder="请输入内容"></el-input>
        <p>cm</p>
      </div>
      <div class="teenager-box">
        <p>体重</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_weight" placeholder="请输入内容"></el-input>
        <p>KG</p>
      </div>
      <div class="teenager-box">
        <p>BMI</p>
        <el-input class="teenager-sr" v-model="bmi" disabled></el-input>
      </div>
      <div class="teenager-box">
        <p>明确需求</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_needs" placeholder="请输入内容"></el-input>
      </div>
      <div class="teenager-box">
        <p>其他原因</p>
        <el-input class="teenager-sr" v-model="postData.S_OP_ICH_otherReason" placeholder="请输入内容"></el-input>
      </div>
    </div>
    <el-dialog
        title="视功能问卷"
        :visible.sync="dialogVisible"
        width="400px"
        >
      <iframe style="width: 375px;height: 500px;overflow-y: auto;" ref="prints" :src="src"></iframe>
    </el-dialog>

  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
export default {
  name: "physicianVisits",
  data() {
    return {
      dialogVisible: false,
      src: '',
      yybz: '',
      value: '',
      options: [
        {
          value: '左眼',
          label: '左眼'
        },
        {
          value: '右眼',
          label: '右眼'
        },
        {
          value: '双眼',
          label: '双眼'
        },
        {
          value: ' ',
          label: ' '
        },
      ],
      checkList: [],
      chooseid: '',
      ids: 1,
      sex: -1,
      state1: '',
      state2:'',
      srpp: '',
      H: '',
      T: '',
      BMI: '',
      xq: '',
      other: '',
      isAdult: true,
      restaurants: [],
      srcList: [
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_1_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_2_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_3_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_4_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_5_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_6_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_7_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_8_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_9_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_10_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_11_C.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/Dquestion1_12_C.jpg",
      ],
      postData: {
        InquiryIntention: '',
        InquiryWhichEyeArray: '',
        InquiryDurationArray: '',
        InquiryOccupation: '',
        InquiryRemark1: ''
      },
      ybList: ['','','','','','','','','','','',''],
      cxList: ['','','','','','','','','','','','']
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('physicianVisits/UpAldult', {
          type: 1,
          obj: n
        })
      },
      immediate: true,
      deep: true
    },
    props: {
      idx: {
        default: -1
      }
    },

    checkList: {
      handler(newName, oldName) {
        let arr = [0,0,0,0,0,0,0,0,0,0,0,0]
        this.checkList.forEach(item => {
          arr[item] = 1
        })
        this.$set(this.postData, 'InquiryIntention', arr)
      },
      immediate: true,
      deep: true
    },

    ybList: {
      handler(n, o) {
        this.postData.InquiryWhichEyeArray = n
      },
      immediate: true,
      deep: true
    },
    cxList: {
      handler(n, o) {
        this.postData.InquiryDurationArray = n
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.src = pf.getUrl('VisualFunctionQuestionnaireVP')+'CustomerId='+this.$store.state.users.CsUser.CustomerId
    this.inits()
  },
  computed: {
    bmi() {
      let num = this.postData.S_OP_ICH_weight/Math.pow(this.postData.S_OP_ICH_height/100, 2)
      return num.toFixed(0)
    }
  },
  methods: {
    inits() {
      this._api.physicianVisits.getChoose(this.$store.state.physicianVisits.xzConsulting)
      .then(res => {
        // console.log(res.GetFullResult)
        if (res.GetFullResult) {
          for (let key in this.postData) {
            this.postData[key] = res.GetFullResult.InquiryFull.Inquiry[key]
          }
          this.$store.commit('physicianVisits/upIsgetData', Math.random().toFixed(6).slice(-6))
          this.$store.commit('physicianVisits/upDatas', res.GetFullResult.InquiryFull.Inquiry)
          if (this.postData.InquiryIntention) {
            let arr = '',newarr = []
            if (typeof this.postData.InquiryIntention == 'string') {
              arr = this.postData.InquiryIntention.split(',')
            }else {
              arr = this.postData.InquiryIntention
            }
            if (arr && arr.length > 0) {
              for (let i = 0; i < arr.length; i++) {
                if (arr[i]) {
                  newarr.push(i.toString())
                }
              }
            }
            this.checkList = newarr
            this.ybList = this.postData.InquiryWhichEyeArray
            this.cxList = this.postData.InquiryDurationArray
          }
        }
      })

      this.sex = this.$store.state.users.CsUser.CustomerGender
      this.srpp = this.$store.state.users.CsUser.CustomerBirthday
    },
    hh() {
      // console.log(this.checkList)
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return [
        { "value": "夜间视力对比白天特别差"},
        { "value": "强光和反光产生视觉强干扰"},
        { "value": "特殊眼睛防护需求"},
        { "value": "特殊视觉质量需求"},
        { "value": "暂无" },
        { "value": " " }
      ]
    },
    handleSelect(item) {
      this.postData.InquiryOccupation = item.value
    }
  },
  mounted() {
    this.restaurants = this.loadAll();
  }
}
</script>

<style scoped lang="scss">
  .yybz {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
    .yybz-sr {
      width: 60%;
    }
  }
  .xzx {
    display: flex;
    align-items: center;
    justify-content: start;
    .zxz-sr {width: 90px;}
  }

  .fxk {margin: 0 .05rem;}
  ::v-deep .fxk .el-checkbox__inner::after {
    width: 8px;
    height: 16px;
    left: 10px;
    top: 2px;
  }
  ::v-deep .fxk .el-checkbox__inner {
    width: 30px;
    height: 30px;
  }
  ::v-deep .fxk .el-checkbox__label {display: none;}
  .nr-box {
    img {
      width: 10vw;
      border-radius: 5px;
    }
  }
  .nr-content {
    display: flex;
    width: 85vw;
    flex-wrap: wrap;
    padding: 0 2vw;
    .zynr {
      width: 140px;
      height: 140px;
      padding: 4px;
      border: 2px solid #8f8e8e;
      border-radius: 5px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 8px;
      p {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: calc(100% - 8px);
        height: 30%;
        background: rgba(0,0,0,0.5);
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .nr-b-box {
      margin:0 10px;
      padding-top: 20px;
    }
  }

  .qt {
    display: flex;
    width: 80vw;
    padding: 2vh 2.5vw;
    align-items: center;
    .xz {
      display: flex;
      align-items: center;
      .xz-box {
        display: flex;
        align-items: center;
        p {width: 4em;padding-right: 1vw;padding-left: 6vw;}
        .srpp {
          width: 12vw;
        }
      }
    }
  }

  .sgn {margin-left: 1.2rem;}

  .teenager {
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    padding: 0 2.5vw;
    margin-bottom: 0.3rem;
    .teenager-box {
      display: flex;
      justify-content: start;
      align-items: center;
      padding-right: 0.3rem;
      padding-top: 0.2rem;
    }
    .teenager-sr {
      width: 1.5rem;
      margin: 0 0.1rem;
    }
  }
</style>
